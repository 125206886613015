import { useState } from 'react'

import { BookmarkPopup } from '@/components/bookmark-popup/bookmark-popup'

import { LoginCodeVerificationForm } from './LoginCodeVerificationForm'
import { LoginForm } from './LoginForm'

export type LoginData = {
  email: string
  password: string
  phoneLastFourDigits: string
}

export const Login = () => {
  const [loginData, setLoginData] = useState<LoginData | null>(null)

  return (
    <>
      <BookmarkPopup />
      {loginData ? (
        <LoginCodeVerificationForm loginData={loginData} />
      ) : (
        <LoginForm setLoginData={setLoginData} />
      )}
    </>
  )
}
