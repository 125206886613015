import { Suspense } from 'react'
import { QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'

import { GenericError } from '@/components/error-communications'
import { PageLoader } from '@/components/loader'
import { PageLayout } from '@/components/page-layout'
import { AuthContext } from '@/context/Auth'
import { DialogProvider } from '@/context/DialogContext'
import { ToastProvider } from '@/context/ToastContext.tsx'
import { useAnalyticsPageView } from '@/hooks/useAnalyticsPageView'
import { globalStyles } from '@/styles/stitches.config'

const RootComponent = () => {
  globalStyles()
  useAnalyticsPageView()
  return (
    <ToastProvider>
      <DialogProvider>
        <Suspense fallback={<PageLoader />}>
          <PageLayout>
            <Outlet />
            <ReactQueryDevtools
              buttonPosition="bottom-left"
              initialIsOpen={false}
            />
            {process.env.NODE_ENV !== 'production' && (
              <TanStackRouterDevtools
                position="bottom-right"
                initialIsOpen={false}
              />
            )}
          </PageLayout>
        </Suspense>
      </DialogProvider>
    </ToastProvider>
  )
}
export const Route = createRootRouteWithContext<{
  queryClient: QueryClient
  auth: AuthContext
}>()({
  component: RootComponent,
  errorComponent: GenericError,
})
