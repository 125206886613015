import { ChangeEvent, FC, useState } from 'react'
import { Box, Button, Input, Typography } from '@branch-messenger/willow-ui'

import { Loader } from '@/components/loader'
import { Description, Title } from '@/components/typography'

import { VerificationTimer } from './VerificationTimer'

interface Props {
  onSubmit: (code: string) => void
  onResendCode: () => void
  phoneLastFourDigits: string
  requestCodePending: boolean
  verifyCodePending: boolean
}

export const CodeVerificationForm: FC<Props> = ({
  onSubmit,
  onResendCode,
  phoneLastFourDigits,
  requestCodePending,
  verifyCodePending,
}) => {
  const [code, setCode] = useState('')

  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (!value) return setCode('')
    setCode(value)
  }

  return (
    <Box $direction="column" $align="start">
      <Title>Enter the code we texted you.</Title>
      {phoneLastFourDigits && (
        <Description size="sm" css={{ mt: '$16' }}>
          To verify your identity, please input the code sent to the phone
          number ending in <strong>{phoneLastFourDigits}</strong>.
        </Description>
      )}

      {requestCodePending ? (
        <Loader />
      ) : (
        <Box $direction="column" $align="start" $mt={10}>
          <Input
            name="confirmationCode"
            type="text"
            placeholder="Enter Code"
            onChange={handleCodeChange}
            disabled={verifyCodePending}
          />
          <Box $mt={1}>
            <Typography $size="sm" $color="textMuted">
              Standard messaging rates may apply.
            </Typography>
          </Box>
        </Box>
      )}

      <VerificationTimer onResendCode={onResendCode} />

      <Box $mt={6}>
        <Button
          onClick={() => onSubmit(code)}
          disabled={!code || requestCodePending || verifyCodePending}
        >
          Enter Code
        </Button>
      </Box>
    </Box>
  )
}
