import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Box, Button, theme, Typography } from '@branch-messenger/willow-ui'
import { yupResolver } from '@hookform/resolvers/yup'

import { PhoneNumberInput } from '@/components/phone-input'
import { Title } from '@/components/typography'

import { PhoneUpdateFormValues } from './PhoneUpdate'

interface Props {
  phone: string
  isPending: boolean
  onSubmit: (values: PhoneUpdateFormValues) => void
}

const schema = yup.object().shape({
  phone: yup.string().required('Phone number is required'),
})

export const PhoneUpdateForm: FC<Props> = ({ phone, isPending, onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = useForm<PhoneUpdateFormValues>({
    defaultValues: {
      phone,
    },
    resolver: yupResolver(schema),
  })

  return (
    <Box $direction="column" $align="start">
      <Title css={{ mb: '$40' }}>What's your new phone number?</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box $direction="column" $align="start" $mt={4} $mb={4}>
          <Controller
            name="phone"
            control={control}
            render={({ field: { value, onChange } }) => (
              <PhoneNumberInput
                onChange={onChange}
                initialPhoneNumber={value}
              />
            )}
          />
          <Typography
            $color="alert"
            $size="sm"
            style={{ marginTop: theme.space[2] }}
          >
            {errors.phone?.message}
          </Typography>
        </Box>
        <Typography
          $size="sm"
          $color="textMuted"
          style={{ marginTop: theme.space[1] }}
        >
          Standard messaging rates may apply.
        </Typography>
        <Box $direction="column" $align="start" $mt={10}>
          <Button
            type="submit"
            style={{ marginRight: 'auto' }}
            disabled={!isDirty || isPending}
          >
            {!phone ? 'Enter Info' : 'Continue'}
          </Button>
        </Box>
      </form>
    </Box>
  )
}
