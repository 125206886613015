import { FC, useState } from 'react'
import { AxiosError } from 'axios'

import {
  useUpdateAccountPhone,
  useUpdateAccountPhoneDeeplink,
  useUpdateAccountPhoneOTP,
} from '@/queries/registration'
import { OldIResponse } from '@/types/common'

import { AccountUpdateCodeVerification } from './AccountUpdateCodeVerification'
import { AccountUpdateEmailSent } from './AccountUpdateEmailSent'
import { PhoneUpdateForm } from './PhoneUpdateForm'

export type PhoneUpdateFormValues = {
  phone: string
}

export const PhoneUpdate: FC = () => {
  const [step, setStep] = useState(0)
  const [phone, setPhone] = useState('')
  const updateAccountPhoneOTP = useUpdateAccountPhoneOTP()
  const updateAccountPhone = useUpdateAccountPhone()
  const updateAccountDeeplink = useUpdateAccountPhoneDeeplink()

  const handlePhoneUpdateSubmit = ({ phone }: PhoneUpdateFormValues) => {
    setPhone(phone)

    updateAccountPhoneOTP.mutate(phone, {
      onSuccess: () => setStep(1),
      onError: (error: unknown) => {
        const errorMessage = (error as AxiosError<OldIResponse>)?.response?.data
          .message
        if (errorMessage === 'Email verification not completed') {
          updateAccountDeeplink.mutate(undefined, {
            onSuccess: () => setStep(2),
          })
        }
      },
    })
  }

  const resendCode = () => {
    updateAccountPhoneOTP.mutate(phone)
  }

  const resendEmailDeepLink = () => {
    updateAccountDeeplink.mutate()
  }

  const confirmCode = (code: string) => {
    updateAccountPhone.mutate({
      phone,
      otp: code,
    })
  }

  const handleEmailSentNavigate = () => {
    updateAccountPhoneOTP.mutate(phone, {
      onSuccess: () => setStep(1),
      onError: () => {
        setPhone('')
        setStep(0)
      },
    })
  }

  switch (step) {
    case 0:
      return (
        <PhoneUpdateForm
          isPending={updateAccountDeeplink.isPending}
          phone={phone}
          onSubmit={handlePhoneUpdateSubmit}
        />
      )
    case 1:
      return (
        <AccountUpdateCodeVerification
          phoneLastFourDigits={phone.slice(-4)}
          resendCode={resendCode}
          confirmCode={confirmCode}
          verifyCodePending={updateAccountPhone.isPending}
          requestCodePending={updateAccountPhoneOTP.isPending}
        />
      )
    case 2:
      return (
        <AccountUpdateEmailSent
          email={''}
          resendCode={resendEmailDeepLink}
          handleNavigate={handleEmailSentNavigate}
        />
      )
    default:
      return (
        <PhoneUpdateForm
          isPending={
            updateAccountPhoneOTP.isPending || updateAccountDeeplink.isPending
          }
          phone={phone}
          onSubmit={handlePhoneUpdateSubmit}
        />
      )
  }
}
