import { FC } from 'react'
import { Box } from '@branch-messenger/willow-ui'

import { BookmarkPopup } from '@/components/bookmark-popup/bookmark-popup'
import { Title } from '@/components/typography'
import { PayoutHistory } from '@/containers/PayoutHistory'
import { useOrg } from '@/selectors/useOrg'

import { AccountProfile } from './AccountProfile'

export const AccountOverview: FC = () => {
  const { orgName } = useOrg()

  return (
    <>
      <BookmarkPopup />
      <Box>
        <Title>Manage your {orgName} payments.</Title>
        <AccountProfile />
        <PayoutHistory />
      </Box>
    </>
  )
}
