import { FC, useState } from 'react'
import { useNavigate } from '@tanstack/react-router'

import { Button } from '@/components/button'
import { Checkbox } from '@/components/checkbox'
import { Box, Flex } from '@/components/layout'
import { Loader } from '@/components/loader'
import { SVGIcon } from '@/components/svgIcon'
import { Title, Typography } from '@/components/typography'
import { useTerms } from '@/queries/registration'

import { TermsLine } from './TermsLine'

export const TermsAndPoliciesAgreement: FC = () => {
  const { data: terms } = useTerms()
  const [termsAgreed, setTermsAgreed] = useState(false)
  const toggleTermsAgreed = () => setTermsAgreed(!termsAgreed)
  const navigate = useNavigate()
  const proceed = () => navigate({ to: '/setup/create-link' })

  if (!terms)
    return (
      <Box direction="column" justify="center" align="center">
        <Loader size="lg" />
      </Box>
    )

  return (
    <Box direction="column">
      <Flex justify="center" css={{ mb: '$24' }}>
        <SVGIcon iconName="sign_form" />
      </Flex>
      <Box direction="column" align="start" fullWidth>
        <Title>Agree to terms, policies & agreements.</Title>
        <TermsLine terms={terms} />
      </Box>
      <Box
        css={{
          backgroundColor: '$background',
          br: '$lg',
          padding: '$16',
          width: 'auto',
        }}
        justify="start"
      >
        <Flex css={{ mr: '$24' }}>
          <Checkbox
            id="agreement"
            checked={termsAgreed}
            onChanged={toggleTermsAgreed}
            mode="circular"
            size="large"
          />
        </Flex>
        <Typography>
          By checking I agree, I have read, understood, and consent to Branch's
          terms, policies, and other agreements listed above.
        </Typography>
      </Box>
      <Box css={{ mt: '$24', mr: 'auto' }}>
        <Button onClick={proceed} disabled={!termsAgreed}>
          {termsAgreed ? 'Continue' : 'Agree to continue'}
        </Button>
      </Box>
    </Box>
  )
}
