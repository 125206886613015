import { FC } from 'react'
import { Box, theme, Typography } from '@branch-messenger/willow-ui'
import { useNavigate } from '@tanstack/react-router'

import { Button } from '@/components/button'
import { SVGIcon } from '@/components/svgIcon'
import { Description, Title } from '@/components/typography'
import { VerificationTimer } from '@/components/verification/VerificationTimer'

interface Props {
  email: string
  navigateTo?: string
  handleNavigate?: () => void
  resendCode?: () => void
}

export const AccountUpdateEmailSent: FC<Props> = ({
  email,
  navigateTo = '/account',
  handleNavigate,
  resendCode,
}) => {
  const navigate = useNavigate()

  const navigateHanlder = () => {
    if (handleNavigate) {
      return handleNavigate()
    }
    return navigate({
      to: navigateTo,
    })
  }

  return (
    <Box $direction="column">
      <Box $justify="center" $mb={6}>
        <SVGIcon iconName="email" />
      </Box>
      <Box $direction="column" $align="start">
        <Title>Check your email.</Title>
        {email && (
          <Description>
            To keep your account safe, please verify it's you using the
            verification link sent to: <Typography $bold>{email}</Typography>
          </Description>
        )}
        <Typography $bold style={{ marginTop: theme.space[2] }}>
          Do not share this link with anyone.
        </Typography>
      </Box>

      {resendCode && <VerificationTimer onResendCode={resendCode} />}

      <Box $mt={6} style={{ marginRight: 'auto' }}>
        <Button onClick={navigateHanlder}>
          {handleNavigate ? 'Continue' : 'Go Back'}
        </Button>
      </Box>
    </Box>
  )
}
