import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  Box,
  Button,
  Input,
  theme,
  Typography,
} from '@branch-messenger/willow-ui'
import { yupResolver } from '@hookform/resolvers/yup'

import { Title } from '@/components/typography'

import { EmailUpdateFormValues } from './EmailUpdate'

interface Props {
  email: string
  isPending: boolean
  onSubmit: (values: EmailUpdateFormValues) => void
}
const schema = yup.object().shape({
  email: yup
    .string()
    .email('Must be a valid email address')
    .required('Email is required'),
})

export const EmailUpdateForm: FC<Props> = ({ email, isPending, onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = useForm<EmailUpdateFormValues>({
    defaultValues: {
      email,
    },
    resolver: yupResolver(schema),
  })

  return (
    <Box $direction="column" $align="start">
      <Title>What's your updated email address?</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box $direction="column" $align="start" $mt={4} $mb={4}>
          <Controller
            name="email"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <Input
                name="email"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
          />
          <Typography
            $color="alert"
            $size="sm"
            style={{ marginTop: theme.space[2] }}
          >
            {errors.email?.message}
          </Typography>
        </Box>
        <Box $direction="column" $align="start">
          <Button
            style={{ marginRight: 'auto' }}
            disabled={!isDirty || isPending}
            type="submit"
          >
            {!email ? 'Enter Info' : 'Continue'}
          </Button>
        </Box>
      </form>
    </Box>
  )
}
