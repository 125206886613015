import { FC } from 'react'
import { isMobile } from 'react-device-detect'
import { Box, Typography } from '@branch-messenger/willow-ui'
import { Cross1Icon } from '@radix-ui/react-icons'

import { useLocalStorage } from '@/hooks/useLocalStorage'

import { SVGIcon } from '../svgIcon'
import {
  BookmarkPopupClose,
  BookmarkPopupCloseButton,
  BookmarkPopupContainer,
  BookmarkPopupContent,
} from './styles'

export const BookmarkPopup: FC = () => {
  const [showBookmarkPopup, setShowBookmarkPopup] = useLocalStorage(
    'showBookmarkPopup',
    true
  )

  const handleClose = () => {
    setShowBookmarkPopup(false)
  }

  if (isMobile)
    return (
      <BookmarkPopupContainer $isMobile $show={showBookmarkPopup}>
        <BookmarkPopupClose $isMobile>
          <BookmarkPopupCloseButton
            $isMobile
            variant="filled"
            color="dark"
            onClick={handleClose}
          >
            <Cross1Icon height={12} width={12} />
          </BookmarkPopupCloseButton>
        </BookmarkPopupClose>
        <BookmarkPopupContent $isMobile $gap={4}>
          <Box>
            <SVGIcon iconName="branch_squared" />
          </Box>
          <Box $display="flex" $direction="column" $align="baseline" $gap={1}>
            <Typography $bold $size="lg">
              Bookmark this site
            </Typography>
            <Typography $size="xs">
              Tap the Share icon, select "Add to Home Screen," and tap "Add."
            </Typography>
          </Box>
        </BookmarkPopupContent>
      </BookmarkPopupContainer>
    )

  return (
    <BookmarkPopupContainer $show={showBookmarkPopup}>
      <BookmarkPopupClose>
        <BookmarkPopupCloseButton
          variant="filled"
          color="dark"
          onClick={handleClose}
        >
          <Cross1Icon />
        </BookmarkPopupCloseButton>
      </BookmarkPopupClose>
      <BookmarkPopupContent $direction="column">
        <Box $my={5}>
          <SVGIcon iconName="branch_squared" />
        </Box>
        <Box
          $display="flex"
          $direction="column"
          $justify="center"
          $align="center"
          $gap={4}
        >
          <Typography $bold $size="xl">
            Bookmark this site
          </Typography>
          <Typography>
            To bookmark, press Ctrl+D (Windows) or Command+D (Mac).
          </Typography>
          <Typography>Then, click "Done" to save it.</Typography>
        </Box>
      </BookmarkPopupContent>
    </BookmarkPopupContainer>
  )
}
